<!--
 * @Description: 质检
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-15 17:27:57
 * @LastEditors: Seven
 * @LastEditTime: 2021-10-26 16:00:25
-->
<template>
  <div class="inspect">
    <div class="tool">
      <div class="customer-tool">
        <span class="tab-bar clearfix">
          <span
            @click="handleClick(item.path)"
            v-for="(item, index) in tabList"
            :class="{ active: item.path === activeName }"
            :key="index"
            >{{ item.name }}</span
          >
        </span>
        <div style="line-height: 40px">
          <!-- 导出 -->
          <!-- <el-button type="primary" class="hq-button" @click="exportInspect" icon="el-icon-download">导出</el-button> -->
          <Export v-if="permUtil.WbCusotmer.invalidExport()" :pageParam="pageParam" :searchParam="searchParam" :total="total" code="inspect" />
        </div>
      </div>
    </div>
    <div style="width: 94%; margin: 0 auto">
      <Search
        v-if="searchParamList.length > 0"
        :searchAllParam="searchAllParam"
        @search="search"
        @reset="reset"
        @searchMore="searchMore"
        @searchList="searchList"
        :searchParamList="searchParamList"
        :searchParamFromApi="searchParamFromApi"
        code="inspect"
      />
    </div>
    <div class="line"></div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '0px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @selection-change="selectionRow"
          @sort-change="sortChange"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="客户编号"
            prop="customerId"
            min-width="120"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="商机创建时间"
            prop="bizCreateTime"
            min-width="140"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="标记无效时间"
            prop="createTime"
            min-width="140"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检时间"
            prop="updateTime"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="无效原因"
            prop="typeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检详情"
            prop="result"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="标记人"
            prop="createName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="质检人"
            prop="inspectUserName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="状态"
            prop="statusName"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.status === 0 ? '#F54955' : '#16B861',
                }"
                >{{ scope.row.statusName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="120"  fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status !== 1 && permUtil.WbCusotmer.invalidInspect()" type="text" @click="inspectClick(scope.row)"
                >质检</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div :class="isScroll?'page_bottom':'page_no_scroll'">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <HqModal :visible="inspectShow" title="质检" width="450" @on-cancel="cancel">
      <el-form ref="form" lazy-validation>
        <el-row class="inspect-modal">
          <el-col :span="24">
            <el-form-item prop="bizStatus">
              <CheckBox
                ref="ChilCheckbox"
                label="是否无效"
                :list="checkList"
                v-bind:select.sync="form.bizStatus"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="form.bizStatus != 0 && form.bizStatus !== null">
            <el-form-item prop="type">
              <dict-select code="INVALID_TYPE">
                <v-select
                  v-model="form.type"
                  slot-scope="props"
                  :items="props.dictData"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="无效原因"
                  item-text="label"
                  item-value="value"
                  outlined
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                ></v-select>
              </dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="result">
              <v-textarea
                v-model="form.result"
                outlined
                :height="80"
                name="input-7-4"
                label="质检详情"
                value=""
              ></v-textarea>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="editSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getInvalidList" />
  </div>
</template>
<script>
import Search from "components/search";
import dictSelect from "components/dictSelect";
import CheckBox from "components/check-box";
import Detail from "./components/detail";
import Export from "./components/export";
import { getUserCustomQuery, getDeptList, getUserList, getTrackTypeList } from "api/public";
import { getInvalidList, doInvalidInspect } from "api/customer";

export default {
  name: "inspect",
  components: {
    Search,
    dictSelect,
    CheckBox,
    Detail,
    Export,
  },
  data() {
    return {
      activeName: "-1",
      tabList: [
        { name: "所有", path: "-1" },
        { name: "未质检", path: "0" },
        { name: "已质检", path: "1" },
        { name: "无需质检", path: "2" },
      ],
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: "" },
        { name: "phone", label: "手机", type: "input", value: "" },
        {
          name: "createTime",
          label: "创建时间",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "invalidTime",
          label: "无效时间",
          type: "date",
          value: [],
          ref: "invalid",
          show: false,
        },
        {
          name: "typeList",
          label: "无效原因",
          type: "dictMult",
          code: "INVALID_TYPE",
          value: "",
        },
        // {
        //   name: "status",
        //   label: "状态",
        //   type: "dict",
        //   code: "INSPECT_STATUS",
        //   value: "",
        // },
        { name: "weiXin", label: "微信", type: "input", value: "" },
        { name: "qq", label: "QQ", type: "input", value: "" },
        { name: "deptIdList", label: "归属部门", type: "multiTree", value: "" },
        {
          name: "createId",
          label: "标记人",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id"
        },
        {
          name: "inspectUserId",
          label: "质检人",
          type: "select",
          value: "",
          option: [],
          text: "userName",
          id: "userId"
        },
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "createTime"],
      searchParamFromApi: [],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      inspectShow: false,
      form: {
        bizStatus: "",
        type: "",
        result: "",
      },
      checkList: [
        { label: "有效商机", value: 0 },
        { label: "无效商机", value: 1 },
      ],
      searchParam: {},
      // 页面是否有滚动条
      isScroll: true
    };
  },
  mounted() {
    this.getSearchList();
    this.getInvalidList();
    this.searchAllParam.map((item) => {
      if (item.name === "deptIdList") {
        getDeptList().then((data) => item.option = data.result);
      } else if (item.name === "createId") {
        getUserList().then((data) => {
          item.option = data.result;
          this.userList = data.result;
        });
      } else if(item.name === "inspectUserId") {
        getTrackTypeList(0).then(data => item.option = data.result)
      }
    });
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
      this.getInvalidList()
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("inspect");
      if (code === 0) {
        this.defaultParamList = ["name", "phone", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
      }
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getInvalidList() {
      if(this.activeName != -1) {
        this.pageParam.status = this.activeName;
        this.searchParam.status = this.activeName;
      } else {
        delete this.pageParam.status
        delete this.searchParam.status
      }
      this.listLoading = true;
      const { code, result, msg } = await getInvalidList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 多选事件--
    selectionRow(val) {
      this.currList = val;
    },

    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getInvalidList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getInvalidList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].asc = !(val.order == "descending")
      this.pageParam.sortItems[0].column = val.prop;
      this.getInvalidList();
    },

    // 质检
    inspectClick(row) {
      this.inspectShow = true;
      this.form.customerId = row.customerId;
      this.form.invalidId = row.invalidId;
      this.$refs.ChilCheckbox.setStyle()
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "createTime") {
              this.searchParam.beginBizCreateTime = item.value[0];
              this.searchParam.endBizCreateTime = item.value[1];
            } else if (item.name === "invalidTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getInvalidList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "createTime" ||
          item.name === "invalidTime") {
            item.value = []
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
    // 保存编辑
    async editSave() {
      console.log('this.form', this.form);
      const { code, msg } = await doInvalidInspect(this.form);
      if (code === 0) {
        this.$message.success(msg);
        this.getInvalidList();
        this.inspectShow = false;
      } else {
        this.$message.error(msg);
      }
    },

    // 取消编辑
    cancel() {
      (this.form = {
        bizStatus: null,
        type: "",
        result: "",
      }),
        (this.inspectShow = false);
        this.$refs.ChilCheckbox.setStyle()
    },
  },
};
</script>
<style lang="scss" scoped>
.inspect {
  padding-bottom: 40px;
  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    width: 94%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .tab-bar {
    overflow: hidden;

    .active {
      color: #409eff;
    }

    > span {
      float: left;
      margin-right: 12px;
      line-height: 40px;
      text-align: center;
      text-decoration: none;
      color: #000;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .search-btn {
    position: absolute;
  }

  .tool-btn {
    position: absolute;
    right: 20px;
    top: 0;
  }
}

.inspect-modal {
  width: 288px;
  margin: 0 auto;

  // .el-col-24 {
  //   margin-top: 18px;
  // }
}
</style>